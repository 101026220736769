const NavigationbarLinks = [
  {
    name: "Home",
    path: "/",
  },
  {
    name: "About",
    path: "/about",
  },
  {
    name: "Contact Us",
    path: "/contact",
  },
  // {
  //   name: "Regions",
  //   path: "/regions",
  // },
]

export default NavigationbarLinks;
