import React from "react"
import { Link } from "gatsby"
import Navbar from "react-bootstrap/Navbar"
import Nav from "react-bootstrap/Nav"
import NavigationLinks from './navigationbarLinks';
import icon from "../../assets/Logo_weiss_mitElementen_schmal.png"
import NavigationLink from './utilities/navigationLink.jsx';
import styled from "styled-components"

const Styles = styled.div`
  a,
  .navbar-brand,
  .navbar-nav .nav-link {
    color: ${props => props.theme.colorNavLinks};
    margin-right: 5px;
    font-size: 150%;
    text-transform: uppercase;
    font-family: "Playlist";
    &:hover {
      color: ${props => props.theme.colorSpecial};
      text-decoration: none;
    }

    span {
      padding-bottom: 0px;
    }
  }
  .nav-item {
    margin-right: 20px;
  }

  .active {
    color: ${props => props.theme.colorSpecial};
  }
  a {
    max-width: 80%;
    max-height: 80%;
  }
`

const Navigationbar = () => {
  return (
    <Styles>
      <Navbar fixed='top' bg="dark" collapseOnSelect expand="lg">
        <Link className="navbar-brand" to="/">
          <img
            src={icon}
            width="50%"
            height="50%"
            className="d-inline-block align-top"
            alt="Footprints away from home logo"
          />
        </Link>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="navbar-right ml-auto">
            {NavigationLinks.map((link, key) => {
              return (<NavigationLink key={key} exactPath={link.name === "Home"} linkPath={link.path} linkName={link.name} />)
            })}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </Styles>
  )
}

export default Navigationbar
