import React from "react"
import styled from "styled-components"
import icon from "../../assets/Logo_weiss_mitElementen_schmal.png"
import OverlayTrigger from "react-bootstrap/OverlayTrigger"
import Tooltip from "react-bootstrap/Tooltip"

const Style = styled.div`
  footer {
    margin-top: 30px;
    background-color: #343a40;
    min-height: 80px;
    color: white;
    padding: 5px;
  }

  p {
    font-size: 30px;
  }

  .fa {
    padding: 20px;
    font-size: 30px;
    width: 50px;
    text-align: center;
    text-decoration: none;
  }

  /* Add a hover effect if you want */
  .fa:hover {
    opacity: 0.7;
  }

  .social-buttons a {
    display: inline-flex;
    text-decoration: none;
    font-size: 350%;
    width: 40px;
    height: 40px;
    color: #00cccc;
    justify-content: center;
    align-items: center;
    position: relative;
    margin: 0 8px;
  }

  .navbar-brand {
    max-width: 60%;
    max-height: 60%;
  }

  @media (max-width: 1200px) {
    .navbar-brand {
      max-width: 50%;
      max-height: 50%;
    }
    .social-buttons a {
      font-size: 200%;
    }
  }

  .social-buttons a::before {
    content: "";
    position: absolute;
    width: 20px;
    height: 20px;
    background: linear-gradient(45deg, #22a6b3, #30336b);
    border-radius: 50%;
    z-index: -1;
    transition: 0.3s ease-in;
  }

  .social-buttons a:hover::before {
    transform: scale(0);
  }

  .social-buttons a i {
    transition: 0.3s ease-in;
  }

  .social-buttons a:hover i {
    background: linear-gradient(45deg, #22a6b3, #30336b);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    transform: scale(1.2);
  }
`

const Footer = () => {
  return (
    <Style>
      <footer className="navbar-fixed-bottom">
        <div className="row">
          <div className="col">
            Footprints away from home &copy; {new Date().getFullYear()} by Julia
            und Toni
            <br /> Contact us: footprintsawayfromhome@gmail.com
          </div>
          <div className="col"></div>
          <div style={{}} className="col justify-content-end">
            Follow us: <br />
            <div
              style={{ marginTop: "10px", marginBottom: "auto" }}
              className="social-buttons"
            >
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip>Facebook</Tooltip>}
              >
                <a target="_blank" className="mr-3" href="#">
                  <i className="fab fa-facebook"></i>
                </a>
              </OverlayTrigger>

              <OverlayTrigger
                placement="top"
                overlay={<Tooltip>Instagram</Tooltip>}
              >
                <a
                  target="_blank"
                  href="https://www.instagram.com/_footprintsawayfromhome_/"
                >
                  <i class="fab fa-instagram"></i>
                </a>
              </OverlayTrigger>
            </div>
          </div>
        </div>
      </footer>
    </Style>
  )
}

export default Footer
