import React from "react"
import { Jumbotron as Jumbo } from "react-bootstrap"
import styled from "styled-components"
import landingPageImage from "../../assets/stone.jpg"

const Styles = styled.div`
  .jumbo {
    position: relative;
    background: url(${landingPageImage}) no-repeat center;
    background-attachment: fixed;
    -webkit-background-size: 100% 100%;
    -moz-background-size: 100% 100%;
    -o-background-size: 100% 100%;
    background-size: 100% 100%;
    height: 800px;
    background-size: cover;

    @media (max-width: 1200px) {
      background-attachment: inherit;
      height: 300px;
    }
  }
`

const Jumbotron = () => {
  return (
    <Styles>
      <Jumbo fluid className="jumbo" />
    </Styles>
  )
}

export default Jumbotron
