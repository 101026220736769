import React from "react"
import { Link } from "gatsby"
import Nav from "react-bootstrap/Nav"

const NavigationLink = props => {
  return (
    <Nav.Item>
      {props.exactPath ? (
        <Link activeClassName="active" to={props.linkPath} exact>
          {props.linkName}
        </Link>
      ) : (
        <Link activeClassName="active" to={props.linkPath}>
          {props.linkName}
        </Link>
      )}
    </Nav.Item>
  )
}

export default NavigationLink;
